import React from 'react'
import LogoHeader from '../../assets/images/logo-header.png';
import './footer.css';
import Linkedin from '../../assets/images/LinkedinGrey.png';
import Telegram from '../../assets/images/telegramGrey.png';
import Twitter from '../../assets/images/TwitterGrey.png';
import Instagram from '../../assets/images/InstagramGrey.png';

const Footer = () => {

  return (
    <div className='footer-background'>
      <section className='footer'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-half'>
              <div className='d-flex-center'>
                <a ><img src={LogoHeader} /></a><p>Certify<b>FOREVER</b></p>
              </div>
              <p>Certifyforever by Dapps Factory</p>
            </div>
            <div className='column'>
              <h6>Product</h6>
              <a href="#digitalCertificates">Digital Certificates</a>
              <a href="#features">Features</a>
              {/* <a href="#blog">Blog</a>
              <a href="#reviews">Reviews</a> */}
            </div>
            {/* <div className='column'>
              <h6>Info</h6>
              <a>About Us</a>
              <a>FAQ</a>
              <a>Privacy Policy</a>
              <a>Terms of Service</a>
            </div> */}
            <div className='footer-third column'>
              <h6>Dapps Factory</h6>
              <a href="https://dappsfactory.io/" target="_blank" rel="noreferrer">About us</a>
              <a href="https://dappsfactory.io/#contact" target="_blank" rel="noreferrer">Contact us</a>
            
              <div>
                <a href="https://www.linkedin.com/company/dappsfactory/" target="_blank" rel="noreferrer">
                  <img
                    src={Linkedin}
                    alt="Linkedin"
                    width={20}
                    className="mx-2"
                    style={{ filter: 'brightness(0) invert(1)' }}
                  />
                </a>
                <a href="https://t.me/dappsfactory" target="_blank" rel="noreferrer">
                  <img
                    src={Telegram}
                    alt="Telegram"
                    width={20}
                    className="mx-2"
                    style={{ filter: 'brightness(0) invert(1)' }}
                  />
                </a>
                <a href="https://twitter.com/DApp_Factory" target="_blank" rel="noreferrer">
                  <img
                    src={Twitter}
                    alt="Twitter"
                    width={20}
                    className="mx-2"
                    style={{ filter: 'brightness(0) invert(1)' }}
                  />
                </a>
                <a href="https://www.instagram.com/dappsfactory/" target="_blank" rel="noreferrer">
                  <img
                    src={Instagram}
                    alt="Instagram"
                    width={20}
                    className="mx-2"
                    style={{ filter: 'brightness(0) invert(1)' }}
                  />
                </a>
              </div>

             
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer