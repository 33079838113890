import React from 'react'

import Blog1 from '../../../assets/images/blog1.png';
import Blog2 from '../../../assets/images/blog2.png';
import Blog3 from '../../../assets/images/blog3.png';

import './blog.css';
const Blog = () => {
  return (
    <>
      <section className='blog container pb-5 ' id="blog">
        <div className='pb-5'>
          <h2 className='subtitle'>From the blog</h2>
          <p className='subsubtitle2'>Read the latest news about digital certificates and blockchain</p>
        </div>
        <div className='columns'>
          <div className='column'>
            <a href='https://www.linkedin.com/pulse/blockchain-technology-its-importance-product-traceability/?trackingId=yeusHVmHe4LxaggcxAwlkA%3D%3D' target="_blank" >
              <div className='img-blog'>
                <img src={Blog1} alt='blog 1' />
              </div>
              <h5 className='pt-5'>Blockchain</h5>
              <h4>Blockchain Technology and Its Importance in Product Traceability</h4>
              <h5>25/07/2023</h5>
            </a>
          </div>
          <div className='column'>
            <a href='https://www.linkedin.com/pulse/unlocking-potential-blockchain-dappsfactorys-consulting-services/' target="_blank" >
              <div className='img-blog'>
                <img src={Blog2} alt='blog 2' />
              </div>
              <h5 className='pt-5'>Unlocking the Potential of Blockchain</h5>
              <h4>DAppsFactory's Consulting Services for Decentralized Application</h4>
              <h5>17/07/2023</h5>
            </a>
          </div>
          <div className='column'>
            <a href='https://www.linkedin.com/pulse/security-blockchain-projects-how-technology-ensures-integrity/' target="_blank" >
              <div className='img-blog'>
                <img  src={Blog3} alt='blog 3' />
              </div>
              <h5 className='pt-5'>Security in Blockchain Projects:</h5>
              <h4>How Blockchain Technology Ensures Integrity and Trust in Information</h4>
              <h5>05/07/2023</h5>
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Blog