import React, { useState } from "react";
import NFT from "../../../assets/images/unsplash_TyanDMPxwHc.png";
import Linkedin from "../../../assets/images/Linkedin.png";
import Telegram from "../../../assets/images/telegram.png";
import Twitter from "../../../assets/images/Twitter.png";
import Instagram from "../../../assets/images/Instagram.png";
import TextField from "@mui/material/TextField";

import "./contact-us.css";

const FORM_ENDPOINT_DEV = "http://localhost:5500/api/Contactanos";
const FORM_ENDPOINT =
  "https://certify-forever-api.azurewebsites.net/api/Contactanos";

const ContactUs = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const inputs = e.target.elements;
    let data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    fetch(FORM_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Form response was not ok");
        }

        setSubmitted(true);
      })
      .catch((err) => {
        e.target.submit();
      });
  };

  return (
    <>
      <section className="background-grey contact-us" id="contactUs">
        <div className="container">
          <div className="columns">
            <div className="column is-hidden-mobile">
              <img src={NFT} className="image" alt="Background" />
            </div>
            <div className="column">
              {!submitted ? (
                <form
                  action={FORM_ENDPOINT}
                  onSubmit={handleSubmit}
                  method="POST"
                >
                  <h2 className="subtitle">Contact Us</h2>

                  <div className="field">
                    {/* <label className="label">Full Name</label>
                    <div className="control has-icons-left">
                      <input
                        type="text"
                        placeholder="Your name"
                        name="FullName"
                        className="input"
                        required
                      />
                      
                      <span className="icon is-left">
                        <i className="rbc-icon github"></i>
                      </span>
                    </div> */}
                    <TextField
                      name="FullName"
                      fullWidth
                      color="primary"
                      label="Full Name"
                      variant="standard"
                    />
                  </div>

                  <div className="field">
                    {/* <label className="label">E-mail</label>
                    <div className="control has-icons-left">
                      <input className="input"
                        type="email"
                        required
                        name="email"
                        placeholder="E-mail" />
                      <span className="icon is-left">
                        <i className="rbc-icon lock"></i>
                      </span>
                    </div> */}
                    <TextField
                      fullWidth
                      name="email"
                      color="primary"
                      label="E-mail"
                      variant="standard"
                    />
                  </div>

                  <div className="field">
                    {/*  <label className="label">Message</label>
                    <div className="control has-icons-left">
                      <textarea
                        className="textarea"
                        placeholder="Your message"
                        name="Mensaje"
                        required
                      ></textarea>
                      <span className="icon is-left">
                        <i className="rbc-icon lock"></i>
                      </span>
                    </div> */}
                    <TextField
                      fullWidth
                      name="Mensaje"
                      color="primary"
                      label="Message"
                      variant="standard"
                    />
                  </div>

                  <div className="buttons">
                    <button
                      type="submit"
                      className="is-primary is-rounded is-fullwidth button"
                      tabIndex="0"
                    >
                      Contact Us
                    </button>
                  </div>
                </form>
              ) : (
                <>
                  <h2>Thank you!</h2>
                  <div>We'll be in touch soon.</div>
                </>
              )}
            </div>
            <div className="column contact-column">
              <div>
                <h6 className="title-column">Contact</h6>
                <p>hello@dappsfactory.io</p>
              </div>
              <div>
                <h6 className="title-column">Based in</h6>
                <p>Buenos Aires, Argentina</p>
              </div>
              <div>
                <a href="https://www.linkedin.com/company/dappsfactory/" target="_blank" rel="noreferrer">
                  <img
                    src={Linkedin}
                    alt="Linkedin"
                    width={20}
                    className="mx-2"
                  />
                </a>
                <a href="https://t.me/dappsfactory" target="_blank" rel="noreferrer">
                  <img
                    src={Telegram}
                    alt="Telegram"
                    width={20}
                    className="mx-2"
                  />
                </a>
                <a href="https://twitter.com/DApp_Factory" target="_blank" rel="noreferrer">
                  <img
                    src={Twitter}
                    alt="Twitter"
                    width={20}
                    className="mx-2"
                  />
                </a>
                <a href="https://www.instagram.com/dappsfactory/" target="_blank" rel="noreferrer">
                  <img
                    src={Instagram}
                    alt="Instagram"
                    width={20}
                    className="mx-2"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
